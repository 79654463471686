import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Laser Engraving",
  "description": "For decorating products with logos, typefaces and patterns.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Laser-Cutting", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "laser-engraving"
    }}>{`Laser engraving`}</h2>
    <p>{`Laser engraving creates patterns by burning the target surface's spot by spot, according to the predetermined design. The burning process is done by shooting laser beams onto the area and will create discoloration on the burnt area, according to the pre-designed pattern. Both hard plastics and metal can be processed for laser engraving.`}</p>
    <p>{`NexPCB's standard laser engraving has`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Generally 0.2 mm laser beam size, but the smallest size could be 0.03 mm, and`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Supports DWG, PDF, AI file types. `}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "laser-carving"
    }}>{`Laser carving`}</h2>
    <p>{`In laser carving, the material surface will experience several beam shots in a spot to create an elevation difference (3D Model) throughout the structure. While in laser engraving, the laser beam is shot with no or slight elevation (2D marks) and is mainly done to create discoloration in the shot spots.  `}</p>
    <p>{`Our laser carving works with both metals and plastic and is generally done within seconds or minutes, depending on the complexity and size of the workpiece.`}</p>
    <p>{`NexPCB's standard laser carving has`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Incision width of 0.1-0.5mm,`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Hole center distance tolerance of ±0.1-0.4mm, `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Contour size tolerance of  ±0.1-0.5mm, and`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cut surface roughness of Ra12.5--25μm.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      